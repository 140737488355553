import React, { useState, useEffect, useRef } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SchoolSelection from "../components/schoolSelection";
import { isInViewPort } from "../utils/helpers.js";
import SEO from "../components/seo";

const serializers = {
  types: {
    code: (props) => (
      <pre data-language={props.node.language}>
        <code>{props.node.code}</code>
      </pre>
    ),
  },
};

const BlockContent = require("@sanity/block-content-to-react");

export const data = graphql`
  {
    allSanitySchoolLocations {
      nodes {
        location
      }
    }
    allSanityFees {
      nodes {
        school {
          location
        }
        grade
        basicFees
        basicFeesWithMeals
        hostelFees
      }
    }
    allSanityOtherFees {
      nodes {
        location {
          location
        }
        feeYear
        _rawDetails
      }
    }
    allSanityPaymentProcedure {
      nodes {
        location {
          location
        }
        _rawDetails
      }
    }
  }
`;

const SchoolPricingTable = ({ location, fees, otherFees }) => {
  const otherFeesForCurrentSchoolAllData = otherFees.filter(
    (schoolData) => schoolData.location.location === location
  )[0];
  const otherFeesForCurrentSchool =
    otherFeesForCurrentSchoolAllData._rawDetails;
  const feeYear = otherFeesForCurrentSchoolAllData.feeYear;
  const classesInAscendingOrder = [
    "PG",
    "Nursery",
    "LKG",
    "UKG",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
  ];
  const currentSchoolFees = fees.filter(
    (schoolFee) => schoolFee.school.location === location
  );
  const classesInCurrentSchoolLocation = currentSchoolFees.map(
    (school) => school.grade
  );

  const requiredClasses = classesInAscendingOrder.filter((grade) =>
    classesInCurrentSchoolLocation.includes(grade)
  );

  const hasMealPlan = currentSchoolFees[0].basicFeesWithMeals !== null;
  const hasHostel = currentSchoolFees[4].hostelFees !== null;

  return (
    <div>
      <h2 className="section-head">Fee Strucuture {feeYear}</h2>
      <table
        className="mt-4 md:mt-8 lg:mt-12 formatted-table border-collapse"
        style={{ borderCollapse: "collapse" }}
      >
        <thead className="font-medium">
          <tr className="hover:bg-red-800 hover:text-white md:text-xl">
            <th className="font-medium">Grade</th>
            <th className="font-medium">Basic Fees</th>
            {hasMealPlan ? (
              <th className="font-medium">Basic Fees With Meal Plans</th>
            ) : null}
            {hasHostel ? (
              <th className="font-medium"> Fees with Hostel Facilities </th>
            ) : null}
          </tr>
        </thead>
        <tbody>
          {requiredClasses.map((grade, index) => (
            <tr key={grade} className="hover:bg-blue-800 hover:text-white">
              <td className="font-medium">{grade}</td>
              <td>
                {
                  currentSchoolFees[
                    classesInCurrentSchoolLocation.indexOf(grade)
                  ].basicFees
                }
              </td>
              {hasMealPlan ? (
                <td>
                  {
                    currentSchoolFees[
                      classesInCurrentSchoolLocation.indexOf(grade)
                    ].basicFeesWithMeals
                  }
                </td>
              ) : null}
              {hasHostel ? (
                <td>
                  {currentSchoolFees[
                    classesInCurrentSchoolLocation.indexOf(grade)
                  ].hostelFees || (
                    <span className="text-sm">
                      Hostel Facility Not Available
                    </span>
                  )}
                </td>
              ) : null}
            </tr>
          ))}
        </tbody>
      </table>

      <BlockContent
        blocks={otherFeesForCurrentSchool}
        imageOptions={{ w: 320, h: 240, fit: "max" }}
        projectId="hdf6d0e0"
        dataset="production"
        serializers={serializers}
        className="sanity-block"
      />
    </div>
  );
};

const SchoolHowToPay = ({ location, howToPay }) => {
  const requiredData = howToPay.filter(
    (schoolData) => schoolData.location.location === location
  )[0]._rawDetails;
  return (
    <div>
      <h3 className="section-head">
        Payment Procedure For <span className="text-red-800">{location}</span>
      </h3>

      <BlockContent
        blocks={requiredData}
        imageOptions={{ w: 320, h: 240, fit: "max" }}
        projectId="hdf6d0e0"
        dataset="production"
        serializers={serializers}
        className="sanity-block"
      />
    </div>
  );
};

// const SchoolLatePayment = ({ location }) => {
// return(
// <h1>Late Payment Policy</h1>
// )
// }

export default function ({ data }) {
  const howToPay = data.allSanityPaymentProcedure.nodes;
  const otherFees = data.allSanityOtherFees.nodes;

  const locations = data.allSanitySchoolLocations.nodes.map(
    (location) => location["location"]
  );
  const fees = data.allSanityFees.nodes;

  const [currentSchoolLocation, setCurrentSchoolLocation] = useState(
    locations[1]
  );
  const [schoolChangeNav, setSchoolChangeNav] = useState(false);
  const schoolSelectionBox = useRef();

  useEffect(() => {
    document.addEventListener("scroll", () => {
      if (isInViewPort(schoolSelectionBox.current)) {
        setTimeout(() => {
          if (isInViewPort(schoolSelectionBox.current)) {
            setSchoolChangeNav(true);
          }
        }, 400);
      } else {
        setTimeout(() => {
          if (!isInViewPort(schoolSelectionBox.current)) {
            setSchoolChangeNav(false);
          }
        }, 400);
      }
    });
    return () => {
      document.removeEventListener("scroll", () => {});
    };
  }, []);

  const scrollToCorrectSchool = () => {
    schoolSelectionBox.current.scrollIntoView();
    window.scrollBy(0, -(window.innerHeight / 2));
  };

  return (
    <Layout>
      <SEO title="Fees" />
      {schoolChangeNav ? (
        <section className="sticky top-0 z-10 mx-0 my-0 px-0 py-0">
          <SchoolSelection
            location={currentSchoolLocation}
            currentSchoolLocation={currentSchoolLocation}
            setCurrentSchoolLocation={setCurrentSchoolLocation}
            locations={locations}
            afterClick={scrollToCorrectSchool}
          />
        </section>
      ) : (
        ""
      )}
      <section className="lg:border-r lg:border-b lg:border-gray-600 lg:mt-16">
        <h1 className="section-head lg:text-center ">
          School Fees and Payment Methods
        </h1>
        <p className="mt-4 lg:px-12 lg:mt-8 lg:text-center">
          Towards our mission of making quality education accessible to
          everyone, we have worked hard to put up a competitive pricing without
          compromisng the quality of the education we provide. Please select the
          desired school for details.
        </p>
        <div className="md:px-16 md:flex md:justify-center md:mt-12">
          {locations.map((location, index) => (
            <button
              className={`md:block md:mr-12 button-outline bg-gray-600 text-white py-4 px-6 my-4 mr-4 ${
                currentSchoolLocation === location ? "bg-red-800" : ""
              }`}
              onClick={() => {
                setCurrentSchoolLocation(location);
                // scrollToCorrectSchool()
              }}
              key={index}
            >
              {" "}
              Bloom Nepal School, <span className="capitalize">{location}</span>
            </button>
          ))}
        </div>
      </section>

      <div ref={schoolSelectionBox}>
        <section className="lg:border-l lg:border-b lg:border-gray-600 lg:mt-48 md:mt-32 mt-16">
          <SchoolPricingTable
            location={currentSchoolLocation}
            fees={fees}
            otherFees={otherFees}
          />
        </section>
        <section className="lg:border-r lg:border-b lg:border-gray-600">
          <SchoolHowToPay
            location={currentSchoolLocation}
            howToPay={howToPay}
          />
        </section>
        {/* To be implemented if Late Paymennt Policies are applicable */}
        {/* <section>
          <h2>Late Payment Policy</h2>
          <SchoolLatePayment location={currentSchoolLocation} />
        </section>
      */}
      </div>
    </Layout>
  );
}
